import * as React from 'react';
import { Query } from 'react-apollo';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { path, routes } from 'inkp-routes/public';
import { getContainers } from 'infra-frontend/helpers/apollo';
import * as t from 'inkp-design-sdk/types.g';

import { SHAREABLE_BY_CODE_QUERY } from '../../graphql/shareable';

function RestoreDesignCart(props: { shareable: t.Shareable; container: any; history: any }) {
  React.useEffect(() => {
    const params: { [key: string]: string } = {};
    // TODO: figure out the typing
    const selectedProduct = (props.shareable.products as any).find(({ selected }: { selected: boolean }) => selected);
    if (selectedProduct) {
      params.product = selectedProduct.productId;
      const selectedColor = selectedProduct.colors.find(({ selected }: { selected: boolean }) => selected);
      if (selectedColor) {
        params.color = selectedColor.name;
      }
    }

    props.container.restoreFromShareable(props.shareable).then(() => {
      props.history.replace(path(routes.app.designTool, params));
    });
  }, []);

  return null;
}

interface SharePageParams {
  code: string;
}

export default function(props: RouteComponentProps<SharePageParams>) {
  return (
    <Query query={SHAREABLE_BY_CODE_QUERY} variables={{ code: props.match.params.code }}>
      {({ loading, error, data: shareableData, client }: any) => {
        if (loading) return null;
        if (error) return <Redirect to={routes.app.base} />;
        if (!shareableData || !shareableData.shareableByCode) return null;
        const { designCart } = getContainers(client);
        return (
          <RestoreDesignCart shareable={shareableData.shareableByCode} container={designCart} history={props.history} />
        );
      }}
    </Query>
  );
}
